import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import {useStaticQuery, graphql, withPrefix} from 'gatsby';

function SEO({ schemaData, description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const schemaOrgJSONLD = [];

  let article = schemaData ? schemaData.type : null

  if (true) {
    schemaOrgJSONLD.push({
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: 'https://www.suwaneemasjid.com',
      name: 'Gwinnett Islamic Circle',
    })
  }

  if (article === 'article') {
    schemaOrgJSONLD.push(
      {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "http://localhost:8000/articles/common-vision-problems"
        },
        "headline": schemaData.title,
        "description": schemaData.title,
        "image": {
          "@type": "ImageObject",
          "url": schemaData.thumbnailImage.localFile.url,
          "width": 1280,
          "height": 720
        },
        "author": {
          "@type": "Organization",
          "name": "Gwinnett Islamic Circle"
        },
        "publisher": {
          "@type": "Organization",
          "name": "Gwinnett Islamic Circle",
          "logo": {
            "@type": "ImageObject",
            "url": "images.ctfassets.net/pm9nc6fj35ph/1KDYpfYoYezbKDNCN4g8AM/bdbe47954a67c61c0bfd13df5db97167/logo.png",
            "width": 200,
            "height": 200
          }
        },
        "datePublished": "2019-09-11",
        "dateModified": "2019-09-18"
      }
    )
  }

  const metaDescription = description || site.siteMetadata.description
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <html lang="en" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="apple-touch-icon" sizes="180x180" href={`${withPrefix('/')}images/apple-touch-icon.png`} />
      <link rel="icon" type="image/png" href={`${withPrefix('/')}images/favicon-32x32.png`} sizes="32x32" />
      <link rel="icon" type="image/png" href={`${withPrefix('/')}images/favicon-16x16.png`} sizes="16x16" />
      <link rel="mask-icon" href={`${withPrefix('/')}images/safari-pinned-tab.svg`} color="#ff4400" />
      <meta name="theme-color" content="#fff" />
      <meta property="og:type" content="business.business" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content="/" />
      <meta property="og:image" content={`${withPrefix('/')}images/og-image.jpg`} />
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: `Our Mission is to promote an Islamic way of life, as described in the Holy Quran and the Sunnah of Prophet Muhammad (Salallahu Alayhi Wasallam), through worship, learning and socialization.`,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
