import {Link, useStaticQuery, graphql} from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from 'react'
import {navigate} from "@reach/router"
import {slugify} from "../helpers"

export const DropdownMenu = (props) => {
  const goTo = (m) => {
    // console.log(m.name + ' was clicked')
    navigate(m.title ? `/page/${slugify(m.title)}` : m.name === 'Home' ? '/' : '')
  }
  const getMenuItemTitle = (menuItem, index, depthLevel) => menuItem.title ? menuItem.title : menuItem.name;
  const getMenuItem = (menuItem, depthLevel, index) => {
    let title = getMenuItemTitle(menuItem, index, depthLevel);
    if (menuItem.childPage && menuItem.childPage.length > 0) {
      return (
        <li key={index} onClick={e => {
          goTo(menuItem);
          e.stopPropagation();
        }} className={'text-center cursor-pointer rounded'}>
          <p className={'leading-tight px-2 py-2'}>
            {title}
            {/*{menuItem.childPage ? <svg style={{width: '24px', height: '24px'}} viewBox="0 0 24 24">*/}
            {/*  <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>*/}
            {/*</svg> : ''}*/}
          </p>

          <DropdownMenu config={menuItem.childPage} child={true}/>
        </li>
      );
    } else {
      return <li key={index} onClick={e => {
        goTo(menuItem);
        e.stopPropagation();
      }} className={'text-center cursor-pointer rounded'}><p className={'leading-tight px-2 py-2'}>{title}</p></li>;
    }
  };

  let {config} = props;
  let options = [];
  config.map((item, index) => options.push(getMenuItem(item, 0, index)));
  if (props.childPage && props.childPage === true) {
    return <ul className={'rounded'}>{options}</ul>;
  }
  return <ul className="dropdown-menu w-full flex justify-center rounded">{options}</ul>;
};

export const MobileMenu = ({data, depth = 0}) => {
  const [show, setShow] = useState(0)
  const goTo = (m, depth, index) => {
    navigate(m.title ? `/page/${slugify(m.title)}` : m.name === 'Home' ? '/' : '')

    setShow(depth + index);
    if (depth + index === show) {
      setShow((depth - 1) + (index - 1));
    }
  }
  if (data) {
    return data.childPage.map((subMenu, index) =>
      <div className={'px-2 py-1 mb-1'}>
        <div className={'px-2 py-1 cursor-pointer flex flex-row'} onClick={e => {
          goTo(subMenu, depth, index);
          e.stopPropagation();
        }}>
          <p>{subMenu.name ? subMenu.name : subMenu.title}</p>
          {subMenu.childPage ? <svg style={{width: '24px', height: '24px'}} viewBox="0 0 24 24">
            <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
          </svg> : ''}
        </div>
        {subMenu.childPage && show === index + depth && <MobileMenu className={'positive'} data={subMenu} depth={depth + 1}/>}
      </div>)
  }
}

export const Donate = ({rest}) =>  <div className={`flex items-center p-2 ${rest}`}>
    <Link to={'/page/donate-now/'} className="py-1 px-4 border border-brand-main  hover:border-brand-main bg-brand-main text-white  hover:text-brand-main hover:bg-white rounded uppercase">Donate now</Link>
</div>

export const Logo = () => <Link to="/" className="ml-4 flex" title="Logo">
  <svg width="83" height="87" viewBox="0 0 83 87" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.5005 45.2199C14.0005 48.7199 6.27136 64.4349 6.49951 79.7202H60.5V73.2187L40 72.7187V66.7187H68.0005V86.2197H0V73.7187C0 73.7187 0.49951 55.2178 13.5 42.7187C26.5005 30.2196 44.5 33.7187 47.5005 34.7197C50.501 35.7208 57.5005 38.7197 62.0005 46.7197C63.5291 49.0128 64.5903 51.4462 65.3271 53.7197C65.3876 53.9004 65.4464 54.0878 65.5034 54.2806C67 59.1924 67 63.2199 67 63.2199H60.0005C58.4919 51.9027 53.5551 44.133 46.5005 41.7199C37.0005 38.4705 29.0005 41.7199 21.5005 45.2199Z" fill="#0F6F39"/>
    <path d="M21.5005 45.2199C14.0005 48.7199 6.27136 64.4349 6.49951 79.7202H60.5V73.2187L40 72.7187V66.7187H68.0005V86.2197H0V73.7187C0 73.7187 0.49951 55.2178 13.5 42.7187C26.5005 30.2196 44.5 33.7187 47.5005 34.7197C50.501 35.7208 57.5005 38.7197 62.0005 46.7197C63.5291 49.0128 64.5903 51.4462 65.3271 53.7197C65.3876 53.9004 65.4464 54.0878 65.5034 54.2806C67 59.1924 67 63.2199 67 63.2199H60.0005C58.4919 51.9027 53.5551 44.133 46.5005 41.7199C37.0005 38.4705 29.0005 41.7199 21.5005 45.2199Z" fill="url(#paint0_linear)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M34.0005 32.7197C40.904 32.7197 46.5005 27.1233 46.5005 20.2197C46.5005 19.7739 46.4771 19.3334 46.4316 18.8996C45.8478 23.8664 41.6241 27.7197 36.5005 27.7197C30.9776 27.7197 26.5005 23.2426 26.5005 17.7197C26.5005 12.5961 30.3538 8.37242 35.3206 7.7886C34.8868 7.74307 34.4464 7.71973 34.0005 7.71973C27.0969 7.71973 21.5005 13.3162 21.5005 20.2197C21.5005 27.1233 27.0969 32.7197 34.0005 32.7197Z" fill="#077335"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M34.0005 32.7197C40.904 32.7197 46.5005 27.1233 46.5005 20.2197C46.5005 19.7739 46.4771 19.3334 46.4316 18.8996C45.8478 23.8664 41.6241 27.7197 36.5005 27.7197C30.9776 27.7197 26.5005 23.2426 26.5005 17.7197C26.5005 12.5961 30.3538 8.37242 35.3206 7.7886C34.8868 7.74307 34.4464 7.71973 34.0005 7.71973C27.0969 7.71973 21.5005 13.3162 21.5005 20.2197C21.5005 27.1233 27.0969 32.7197 34.0005 32.7197Z" fill="url(#paint1_linear)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M75.4525 3.25497C76.2969 3.13165 76.9001 2.39477 76.8763 1.56216C76.8944 1.63281 76.9089 1.7051 76.9196 1.77885C77.0687 2.7991 76.4504 3.75441 75.5005 4.06013V6.71959H76.5005L78.0005 7.72589L79.5005 10.2416L81.0005 12.2542L80.5005 12.7573L80.0005 14.7699V18.2919L82.5005 19.2982V22.8203L81.5005 23.8265V25.8391L80.5005 27.6119V86.7196H75.5005H74.5005H69.5005V27.6119L68.5005 25.8391V23.8265L67.5005 22.8203V19.2982L70.0005 18.2919V14.7699L69.5005 12.7573L69.0005 12.2542L70.5005 10.2416L72.0005 7.72589L73.5005 6.71959H74.5005V4.12986C73.6399 3.97892 72.9344 3.29211 72.8012 2.38036C72.6351 1.24309 73.4224 0.186505 74.5597 0.0204044C74.6331 0.00968978 74.7061 0.0029423 74.7785 0C73.9744 0.215702 73.4413 1.00418 73.5646 1.84821C73.6975 2.75802 74.5427 3.38785 75.4525 3.25497Z" fill="#06713B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M75.4525 3.25497C76.2969 3.13165 76.9001 2.39477 76.8763 1.56216C76.8944 1.63281 76.9089 1.7051 76.9196 1.77885C77.0687 2.7991 76.4504 3.75441 75.5005 4.06013V6.71959H76.5005L78.0005 7.72589L79.5005 10.2416L81.0005 12.2542L80.5005 12.7573L80.0005 14.7699V18.2919L82.5005 19.2982V22.8203L81.5005 23.8265V25.8391L80.5005 27.6119V86.7196H75.5005H74.5005H69.5005V27.6119L68.5005 25.8391V23.8265L67.5005 22.8203V19.2982L70.0005 18.2919V14.7699L69.5005 12.7573L69.0005 12.2542L70.5005 10.2416L72.0005 7.72589L73.5005 6.71959H74.5005V4.12986C73.6399 3.97892 72.9344 3.29211 72.8012 2.38036C72.6351 1.24309 73.4224 0.186505 74.5597 0.0204044C74.6331 0.00968978 74.7061 0.0029423 74.7785 0C73.9744 0.215702 73.4413 1.00418 73.5646 1.84821C73.6975 2.75802 74.5427 3.38785 75.4525 3.25497Z" fill="url(#paint2_linear)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M72.5005 86.7197L72.5005 27.7197L73.5005 27.7197L73.5005 86.7197L72.5005 86.7197Z" fill="#0B6F36"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M72.5005 86.7197L72.5005 27.7197L73.5005 27.7197L73.5005 86.7197L72.5005 86.7197Z" fill="url(#paint3_radial)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M76.5005 86.7197L76.5005 27.7197L77.5005 27.7197L77.5005 86.7197L76.5005 86.7197Z" fill="#0B6F36"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M76.5005 86.7197L76.5005 27.7197L77.5005 27.7197L77.5005 86.7197L76.5005 86.7197Z" fill="url(#paint4_radial)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M80.5005 28.7197H69.5005V27.7197H80.5005V28.7197Z" fill="#06713B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M80.5005 28.7197H69.5005V27.7197H80.5005V28.7197Z" fill="url(#paint5_linear)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M81.5005 25.7197H68.5005V24.7197H81.5005V25.7197Z" fill="#06713B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M81.5005 25.7197H68.5005V24.7197H81.5005V25.7197Z" fill="url(#paint6_linear)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M81.5005 23.7197H68.5005L67.5005 22.7197L82.5005 22.7197L81.5005 23.7197Z" fill="#06713B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M81.5005 23.7197H68.5005L67.5005 22.7197L82.5005 22.7197L81.5005 23.7197Z" fill="url(#paint7_linear)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M81.0005 18.7197H69.0005L70.0005 17.7197H80.0005L81.0005 18.7197Z" fill="#06713B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M81.0005 18.7197H69.0005L70.0005 17.7197H80.0005L81.0005 18.7197Z" fill="url(#paint8_linear)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M80.5005 12.7197H69.5005L70.0005 14.7197H80.0005L80.5005 12.7197Z" fill="#06713B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M80.5005 12.7197H69.5005L70.0005 14.7197H80.0005L80.5005 12.7197Z" fill="url(#paint9_linear)"/>
    <defs>
      <linearGradient id="paint0_linear" x1="34.0002" y1="33.5029" x2="34.0002" y2="86.2197" gradientUnits="userSpaceOnUse">
        <stop stopColor="#89C344"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint1_linear" x1="34.0005" y1="7.71973" x2="34.0005" y2="32.7197" gradientUnits="userSpaceOnUse">
        <stop stopColor="#89C33F"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint2_linear" x1="75.0005" y1="0" x2="75.0005" y2="86.7196" gradientUnits="userSpaceOnUse">
        <stop stopColor="#86BF3D"/>
        <stop offset="0.46875" stopColor="white" stopOpacity="0"/>
      </linearGradient>
      <radialGradient id="paint3_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(73.0005 57.2197) rotate(-180) scale(0.499999 29.5)">
        <stop stopColor="#89C24D"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint4_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(77.0005 57.2197) rotate(-180) scale(0.499999 29.5)">
        <stop stopColor="#89C24D"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
      </radialGradient>
      <linearGradient id="paint5_linear" x1="75.0005" y1="27.7197" x2="75.0005" y2="28.7197" gradientUnits="userSpaceOnUse">
        <stop stopColor="#86BF3D"/>
        <stop offset="0.46875" stopColor="white" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint6_linear" x1="75.0005" y1="24.7197" x2="75.0005" y2="25.7197" gradientUnits="userSpaceOnUse">
        <stop stopColor="#86BF3D"/>
        <stop offset="0.46875" stopColor="white" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint7_linear" x1="75.0005" y1="22.7197" x2="75.0005" y2="23.7197" gradientUnits="userSpaceOnUse">
        <stop stopColor="#86BF3D"/>
        <stop offset="0.46875" stopColor="white" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint8_linear" x1="75.0005" y1="17.7197" x2="75.0005" y2="18.7197" gradientUnits="userSpaceOnUse">
        <stop stopColor="#86BF3D"/>
        <stop offset="0.46875" stopColor="white" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint9_linear" x1="75.0005" y1="14.7197" x2="75.0005" y2="12.7197" gradientUnits="userSpaceOnUse">
        <stop stopColor="#86BF3D"/>
        <stop offset="0.46875" stopColor="white" stopOpacity="0"/>
      </linearGradient>
    </defs>
  </svg>

  <div className={'flex flex-col justify-center ml-1 text-sm'}>
    <p className={'font-semibold text-navy-500'}>Gwinnett</p>
    <p className={'font-semibold text-navy-500'}>Islamic</p>
    <p className={'font-semibold text-navy-500'}>Circle</p>
  </div>
</Link>

const Navbar = ({siteTitle}) => {
  const data = useStaticQuery(graphql`
    query {
        contentfulMenuItem(name: {eq: "Main Navigation menu"}) {
            name
            childPage {
              ... on ContentfulMenuItem {
                id
                name
                childPage {
                  ... on ContentfulMenuItem {
                    id
                    name
                    childPage {
                      ... on ContentfulMenuItem {
                        id
                        name
                        childPage {
                          ... on ContentfulMenuItem {
                            id
                            name
                            childPage {
                              ... on ContentfulMenuItem {
                                id
                                name
                                childPage {
                                  ... on ContentfulMenuItem {
                                    id
                                    name
                                  }
                                  ... on ContentfulPages {
                                    id
                                    title
                                  }
                                }
                              }
                              ... on ContentfulPages {
                                id
                                title
                              }
                            }
                          }
                          ... on ContentfulPages {
                            id
                            title
                          }
                        }
                      }
                      ... on ContentfulPages {
                        id
                        title
                      }
                    }
                  }
                  ... on ContentfulPages {
                    id
                    title
                  }
                }
              }
              ... on ContentfulPages {
                id
                title
              }
            }
          }
    }
  `)
  const menuData = data.contentfulMenuItem;
  const [showMenu, setShowMenu] = useState(false);

  return (
    <nav className="mx-auto container">
      <div id="navMenu" className={`text-brand-main bg-white items-center  flex flex-col lg:flex-row  lg:justify-between transition px-4 md:px-8 mb-0 md:my-2`}>
          <Logo/>
          <div className={'flex flex-wrap content-center items-center my-2  justify-between'}>
            <div className={'flex flex-row content-center my-2 font-bold mr-0 md:mr-8 w-full md:w-72 items-center justify-center'}>
              <svg className={'pr-2'}  width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M9 2C7.61555 2 6.26219 2.41053 5.11105 3.17969C3.95991 3.94884 3.06271 5.04207 2.53288 6.32113C2.00306 7.60019 1.86441 9.00764 2.13448 10.3655C2.40454 11.7233 3.07115 12.9706 4.05003 13.9495C4.05006 13.9496 4.04999 13.9495 4.05003 13.9495L8.29403 18.1925C8.38671 18.2853 8.49721 18.3593 8.61836 18.4095C8.7395 18.4598 8.86936 18.4856 9.0005 18.4856C9.13165 18.4856 9.26151 18.4598 9.38265 18.4095C9.5038 18.3593 9.61386 18.2857 9.70655 18.193L13.9499 13.9496C13.9499 13.9496 13.9499 13.9496 13.9499 13.9496C14.9288 12.9706 15.5955 11.7233 15.8655 10.3655C16.1356 9.00764 15.997 7.60019 15.4671 6.32113C14.9373 5.04207 14.0401 3.94884 12.889 3.17969C11.7378 2.41053 10.3845 2 9 2ZM3.99992 1.51674C5.47995 0.527829 7.21999 0 9 0C10.78 0 12.5201 0.527829 14.0001 1.51674C15.4801 2.50565 16.6337 3.91123 17.3149 5.55574C17.9961 7.20025 18.1743 9.00983 17.8271 10.7556C17.4799 12.5015 16.6228 14.1051 15.3641 15.3638L11.1215 19.6065C11.1213 19.6066 11.1216 19.6063 11.1215 19.6065C10.8431 19.885 10.5123 20.1063 10.1485 20.2571C9.78454 20.408 9.39445 20.4856 9.0005 20.4856C8.60655 20.4856 8.21646 20.408 7.85253 20.2571C7.4886 20.1063 7.15797 19.8852 6.87955 19.6065L2.63598 15.3639C1.37735 14.1052 0.520132 12.5015 0.172903 10.7556C-0.174327 9.00983 0.00393033 7.20025 0.685133 5.55574C1.36633 3.91123 2.51989 2.50565 3.99992 1.51674Z"
                      fill="#1D4B54"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M9 6.99951C8.46957 6.99951 7.96086 7.21023 7.58579 7.5853C7.21071 7.96037 7 8.46908 7 8.99951C7 9.52995 7.21071 10.0387 7.58579 10.4137C7.96086 10.7888 8.46957 10.9995 9 10.9995C9.53043 10.9995 10.0391 10.7888 10.4142 10.4137C10.7893 10.0387 11 9.52995 11 8.99951C11 8.46908 10.7893 7.96037 10.4142 7.5853C10.0391 7.21023 9.53043 6.99951 9 6.99951ZM6.17157 6.17108C6.92172 5.42094 7.93913 4.99951 9 4.99951C10.0609 4.99951 11.0783 5.42094 11.8284 6.17108C12.5786 6.92123 13 7.93865 13 8.99951C13 10.0604 12.5786 11.0778 11.8284 11.8279C11.0783 12.5781 10.0609 12.9995 9 12.9995C7.93913 12.9995 6.92172 12.5781 6.17157 11.8279C5.42143 11.0778 5 10.0604 5 8.99951C5 7.93865 5.42143 6.92123 6.17157 6.17108Z"
                      fill="#1D4B54"/>
              </svg>
              <p>80 Celebration Dr, Suwanee, GA 30024</p>
            </div>
            <div className={'flex flex-wrap content-center my-2 font-bold mr-0 md:mr-8'}>
              <svg className={'pr-2'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H8.28C8.48979 3.00016 8.69422 3.0663 8.86436 3.18905C9.03449 3.3118 9.1617 3.48496 9.228 3.684L10.726 8.177C10.8019 8.40534 10.7929 8.65339 10.7007 8.87564C10.6085 9.0979 10.4393 9.27945 10.224 9.387L7.967 10.517C9.07332 12.9655 11.0345 14.9267 13.483 16.033L14.613 13.776C14.7205 13.5607 14.9021 13.3915 15.1244 13.2993C15.3466 13.2071 15.5947 13.1981 15.823 13.274L20.316 14.772C20.5152 14.8383 20.6885 14.9657 20.8112 15.136C20.934 15.3064 21.0001 15.511 21 15.721V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H18C9.716 21 3 14.284 3 6V5Z" stroke="#1D4B54" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <a href="tel:678-800-1199">(678) 800-1199</a>

            </div>
            <div className={'flex flex-wrap content-center my-2'}>
              <a className={'bg-white m-1 rounded-full hover:bg-gray-400'} title="facebook" href="https://www.facebook.com/GwinnettIslamicCircle">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2 6C2 4.93913 2.42143 3.92172 3.17157 3.17157C3.92172 2.42143 4.93913 2 6 2H18C19.0609 2 20.0783 2.42143 20.8284 3.17157C21.5786 3.92172 22 4.93913 22 6V18C22 19.0609 21.5786 20.0783 20.8284 20.8284C20.0783 21.5786 19.0609 22 18 22H6C4.93913 22 3.92172 21.5786 3.17157 20.8284C2.42143 20.0783 2 19.0609 2 18V6ZM6 4C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H12V13H11C10.7348 13 10.4804 12.8946 10.2929 12.7071C10.1054 12.5196 10 12.2652 10 12C10 11.7348 10.1054 11.4804 10.2929 11.2929C10.4804 11.1054 10.7348 11 11 11H12V9.5C12 8.57174 12.3687 7.6815 13.0251 7.02513C13.6815 6.36875 14.5717 6 15.5 6H16.1C16.3652 6 16.6196 6.10536 16.8071 6.29289C16.9946 6.48043 17.1 6.73478 17.1 7C17.1 7.26522 16.9946 7.51957 16.8071 7.70711C16.6196 7.89464 16.3652 8 16.1 8H15.5C15.303 8 15.108 8.0388 14.926 8.11418C14.744 8.18956 14.5786 8.30005 14.4393 8.43934C14.3001 8.57863 14.1896 8.74399 14.1142 8.92597C14.0388 9.10796 14 9.30302 14 9.5V11H16.1C16.3652 11 16.6196 11.1054 16.8071 11.2929C16.9946 11.4804 17.1 11.7348 17.1 12C17.1 12.2652 16.9946 12.5196 16.8071 12.7071C16.6196 12.8946 16.3652 13 16.1 13H14V20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H6Z" fill="#1D4B54"/>
                </svg>
              </a>
              <a className={'bg-white m-1 rounded-full hover:bg-gray-400'} title="youtube" href="https://www.youtube.com/c/SuwaneeMasjid/videos">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.84499 4.77573C10.437 4.40873 13.563 4.40873 16.155 4.77573C18.51 5.10973 20.335 6.76173 20.683 8.54173C21.106 10.6967 21.106 13.3047 20.683 15.4597C20.334 17.2397 18.51 18.8917 16.155 19.2257C13.563 19.5927 10.436 19.5927 7.84499 19.2257C5.48999 18.8917 3.66599 17.2397 3.31699 15.4597C2.89399 13.3047 2.89399 10.6967 3.31699 8.54173C3.66599 6.76173 5.48999 5.10973 7.84499 4.77573ZM16.435 2.79573C13.658 2.40273 10.342 2.40273 7.56499 2.79573C4.54999 3.22273 1.89999 5.37373 1.35499 8.15773C0.881994 10.5667 0.881994 13.4347 1.35499 15.8437C1.89999 18.6277 4.54999 20.7787 7.56499 21.2057C10.342 21.5987 13.658 21.5987 16.435 21.2057C19.45 20.7787 22.1 18.6277 22.645 15.8437C23.118 13.4347 23.118 10.5667 22.645 8.15773C22.1 5.37373 19.45 3.22273 16.435 2.79573ZM10.555 7.16873C10.4044 7.06825 10.2293 7.01054 10.0485 7.00175C9.86768 6.99297 9.68786 7.03344 9.52823 7.11885C9.3686 7.20426 9.23515 7.33141 9.14212 7.48672C9.04909 7.64203 8.99997 7.81968 8.99999 8.00073V16.0007C8.99997 16.1818 9.04909 16.3594 9.14212 16.5147C9.23515 16.67 9.3686 16.7972 9.52823 16.8826C9.68786 16.968 9.86768 17.0085 10.0485 16.9997C10.2293 16.9909 10.4044 16.9332 10.555 16.8327L16.555 12.8327C16.692 12.7414 16.8042 12.6177 16.8819 12.4725C16.9596 12.3274 17.0002 12.1653 17.0002 12.0007C17.0002 11.8361 16.9596 11.674 16.8819 11.5289C16.8042 11.3838 16.692 11.26 16.555 11.1687L10.555 7.16873ZM14.197 12.0007L11 14.1327V9.86973L14.197 12.0007Z" fill="#1D4B54"/>
                </svg>
              </a>
              <a className={'bg-white m-1 rounded-full hover:bg-gray-400'} title="instagram" href="https://www.instagram.com/gwinnettislamiccircle/">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2 6C2 4.93913 2.42143 3.92172 3.17157 3.17157C3.92172 2.42143 4.93913 2 6 2H18C19.0609 2 20.0783 2.42143 20.8284 3.17157C21.5786 3.92172 22 4.93913 22 6V18C22 19.0609 21.5786 20.0783 20.8284 20.8284C20.0783 21.5786 19.0609 22 18 22H6C4.93913 22 3.92172 21.5786 3.17157 20.8284C2.42143 20.0783 2 19.0609 2 18V6ZM6 4C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H6ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9ZM7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12ZM17.5 8C17.8978 8 18.2794 7.84196 18.5607 7.56066C18.842 7.27936 19 6.89782 19 6.5C19 6.10218 18.842 5.72064 18.5607 5.43934C18.2794 5.15804 17.8978 5 17.5 5C17.1022 5 16.7206 5.15804 16.4393 5.43934C16.158 5.72064 16 6.10218 16 6.5C16 6.89782 16.158 7.27936 16.4393 7.56066C16.7206 7.84196 17.1022 8 17.5 8Z" fill="#1D4B54"/>
                </svg>
              </a>
            </div>
            {/*Mobile menu button*/}
            <div className="flex items-center lg:hidden cursor-pointer" onClick={e => setShowMenu(!showMenu)}>
              <svg style={{width: '40px', height: '40px'}} viewBox="0 0 24 24">
                <path fill="currentColor" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"/>
              </svg>
            </div>

            <Donate rest={'lg:flex hidden '}/>
          </div>

      </div>


      <div className="hidden lg:flex py-2 bg-brand-main text-white items-center w-full align-middle mr-4 rounded z-50">
        {menuData && <DropdownMenu config={menuData.childPage}/>}
      </div>
      {showMenu && <div className="flex flex-col lg:hidden text-white bg-brand-main text-white z-50 absolute w-full py-4">
        <MobileMenu data={menuData}/>
      </div>}
    </nav>
  )
}

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
