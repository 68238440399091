import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import Header from "./header"
import "./layout.css"

export const Logo = () => <Link to="/" className="ml-4 hidden md:flex items-center content-center" title="Logo">
  <svg width="83" height="87" viewBox="0 0 83 87" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.5005 45.2199C14.0005 48.7199 6.27136 64.4349 6.49951 79.7202H60.5V73.2187L40 72.7187V66.7187H68.0005V86.2197H0V73.7187C0 73.7187 0.49951 55.2178 13.5 42.7187C26.5005 30.2196 44.5 33.7187 47.5005 34.7197C50.501 35.7208 57.5005 38.7197 62.0005 46.7197C63.5291 49.0128 64.5903 51.4462 65.3271 53.7197C65.3876 53.9004 65.4464 54.0878 65.5034 54.2806C67 59.1924 67 63.2199 67 63.2199H60.0005C58.4919 51.9027 53.5551 44.133 46.5005 41.7199C37.0005 38.4705 29.0005 41.7199 21.5005 45.2199Z"
      fill="#EFCF46"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M34.0005 32.7197C40.904 32.7197 46.5005 27.1233 46.5005 20.2197C46.5005 19.7739 46.4771 19.3334 46.4316 18.8996C45.8478 23.8664 41.6241 27.7197 36.5005 27.7197C30.9776 27.7197 26.5005 23.2426 26.5005 17.7197C26.5005 12.5961 30.3538 8.37242 35.3206 7.7886C34.8868 7.74307 34.4464 7.71973 34.0005 7.71973C27.0969 7.71973 21.5005 13.3162 21.5005 20.2197C21.5005 27.1233 27.0969 32.7197 34.0005 32.7197Z"
          fill="#EFCF46"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M75.4525 3.25497C76.2969 3.13165 76.9001 2.39477 76.8763 1.56216C76.8944 1.63281 76.9089 1.7051 76.9196 1.77885C77.0687 2.7991 76.4504 3.75441 75.5005 4.06013V6.71959H76.5005L78.0005 7.72589L79.5005 10.2416L81.0005 12.2542L80.5005 12.7573L80.0005 14.7699V18.2919L82.5005 19.2982V22.8203L81.5005 23.8265V25.8391L80.5005 27.6119V86.7196H75.5005H74.5005H69.5005V27.6119L68.5005 25.8391V23.8265L67.5005 22.8203V19.2982L70.0005 18.2919V14.7699L69.5005 12.7573L69.0005 12.2542L70.5005 10.2416L72.0005 7.72589L73.5005 6.71959H74.5005V4.12986C73.6399 3.97892 72.9344 3.29211 72.8012 2.38036C72.6351 1.24309 73.4224 0.186505 74.5597 0.0204044C74.6331 0.00968979 74.7061 0.0029423 74.7785 0C73.9744 0.215702 73.4413 1.00418 73.5646 1.84821C73.6975 2.75802 74.5427 3.38785 75.4525 3.25497Z"
          fill="#EFCF46"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M72.5005 86.7197L72.5005 27.7197L73.5005 27.7197L73.5005 86.7197L72.5005 86.7197Z" fill="#E3BD18"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M76.5005 86.7197L76.5005 27.7197L77.5005 27.7197L77.5005 86.7197L76.5005 86.7197Z" fill="#E3BD18"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M80.5005 28.7197H69.5005V27.7197H80.5005V28.7197Z" fill="#E3BD18"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M81.5005 25.7197H68.5005V24.7197H81.5005V25.7197Z" fill="#E3BD18"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M81.5005 23.7197H68.5005L67.5005 22.7197L82.5005 22.7197L81.5005 23.7197Z" fill="#E3BD18"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M81.0005 18.7197H69.0005L70.0005 17.7197H80.0005L81.0005 18.7197Z" fill="#E3BD18"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M80.5005 12.7197H69.5005L70.0005 14.7197H80.0005L80.5005 12.7197Z" fill="#E3BD18"/>
  </svg>
  <div className={'flex flex-col justify-center ml-1 text-sm'}>
    <p className={'font-semibold'}>Gwinnett</p>
    <p className={'font-semibold'}>Islamic</p>
    <p className={'font-semibold'}>Circle</p>
  </div>
</Link>

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      },

    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="relative min-h-screen">
        <main className="font-sans  mx-auto container">{children}</main>
        <footer className={'bg-brand-main text-white p-4'}>
          <div className={'flex justify-around flex-col md:flex-row mx-auto container'}>
            <Logo />
            <div className={'flex flex-wrap content-center justify-center my-2' }>
              <a className={'hover:bg-gray-400 bg-white m-1 rounded-full w-14 h-14 hover:bg-gray-400'} title="facebook" href="https://www.facebook.com/GwinnettIslamicCircle">
                <svg className={'w-6 h-6 m-4'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2 6C2 4.93913 2.42143 3.92172 3.17157 3.17157C3.92172 2.42143 4.93913 2 6 2H18C19.0609 2 20.0783 2.42143 20.8284 3.17157C21.5786 3.92172 22 4.93913 22 6V18C22 19.0609 21.5786 20.0783 20.8284 20.8284C20.0783 21.5786 19.0609 22 18 22H6C4.93913 22 3.92172 21.5786 3.17157 20.8284C2.42143 20.0783 2 19.0609 2 18V6ZM6 4C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H12V13H11C10.7348 13 10.4804 12.8946 10.2929 12.7071C10.1054 12.5196 10 12.2652 10 12C10 11.7348 10.1054 11.4804 10.2929 11.2929C10.4804 11.1054 10.7348 11 11 11H12V9.5C12 8.57174 12.3687 7.6815 13.0251 7.02513C13.6815 6.36875 14.5717 6 15.5 6H16.1C16.3652 6 16.6196 6.10536 16.8071 6.29289C16.9946 6.48043 17.1 6.73478 17.1 7C17.1 7.26522 16.9946 7.51957 16.8071 7.70711C16.6196 7.89464 16.3652 8 16.1 8H15.5C15.303 8 15.108 8.0388 14.926 8.11418C14.744 8.18956 14.5786 8.30005 14.4393 8.43934C14.3001 8.57863 14.1896 8.74399 14.1142 8.92597C14.0388 9.10796 14 9.30302 14 9.5V11H16.1C16.3652 11 16.6196 11.1054 16.8071 11.2929C16.9946 11.4804 17.1 11.7348 17.1 12C17.1 12.2652 16.9946 12.5196 16.8071 12.7071C16.6196 12.8946 16.3652 13 16.1 13H14V20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H6Z" fill="#1D4B54"/>
                </svg>
              </a>
              <a className={'hover:bg-gray-400 bg-white m-1 rounded-full w-14 h-14 hover:bg-gray-400'} title="youtube" href="https://www.youtube.com/c/SuwaneeMasjid/videos">
                <svg className={'w-6 h-6 m-4'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.84499 4.77573C10.437 4.40873 13.563 4.40873 16.155 4.77573C18.51 5.10973 20.335 6.76173 20.683 8.54173C21.106 10.6967 21.106 13.3047 20.683 15.4597C20.334 17.2397 18.51 18.8917 16.155 19.2257C13.563 19.5927 10.436 19.5927 7.84499 19.2257C5.48999 18.8917 3.66599 17.2397 3.31699 15.4597C2.89399 13.3047 2.89399 10.6967 3.31699 8.54173C3.66599 6.76173 5.48999 5.10973 7.84499 4.77573ZM16.435 2.79573C13.658 2.40273 10.342 2.40273 7.56499 2.79573C4.54999 3.22273 1.89999 5.37373 1.35499 8.15773C0.881994 10.5667 0.881994 13.4347 1.35499 15.8437C1.89999 18.6277 4.54999 20.7787 7.56499 21.2057C10.342 21.5987 13.658 21.5987 16.435 21.2057C19.45 20.7787 22.1 18.6277 22.645 15.8437C23.118 13.4347 23.118 10.5667 22.645 8.15773C22.1 5.37373 19.45 3.22273 16.435 2.79573ZM10.555 7.16873C10.4044 7.06825 10.2293 7.01054 10.0485 7.00175C9.86768 6.99297 9.68786 7.03344 9.52823 7.11885C9.3686 7.20426 9.23515 7.33141 9.14212 7.48672C9.04909 7.64203 8.99997 7.81968 8.99999 8.00073V16.0007C8.99997 16.1818 9.04909 16.3594 9.14212 16.5147C9.23515 16.67 9.3686 16.7972 9.52823 16.8826C9.68786 16.968 9.86768 17.0085 10.0485 16.9997C10.2293 16.9909 10.4044 16.9332 10.555 16.8327L16.555 12.8327C16.692 12.7414 16.8042 12.6177 16.8819 12.4725C16.9596 12.3274 17.0002 12.1653 17.0002 12.0007C17.0002 11.8361 16.9596 11.674 16.8819 11.5289C16.8042 11.3838 16.692 11.26 16.555 11.1687L10.555 7.16873ZM14.197 12.0007L11 14.1327V9.86973L14.197 12.0007Z" fill="#1D4B54"/>
                </svg>
              </a>
              <a className={'hover:bg-gray-400 bg-white m-1 rounded-full w-14 h-14 hover:bg-gray-400'} title="instagram" href="https://www.instagram.com/gwinnettislamiccircle/">
                <svg className={'w-6 h-6 m-4'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2 6C2 4.93913 2.42143 3.92172 3.17157 3.17157C3.92172 2.42143 4.93913 2 6 2H18C19.0609 2 20.0783 2.42143 20.8284 3.17157C21.5786 3.92172 22 4.93913 22 6V18C22 19.0609 21.5786 20.0783 20.8284 20.8284C20.0783 21.5786 19.0609 22 18 22H6C4.93913 22 3.92172 21.5786 3.17157 20.8284C2.42143 20.0783 2 19.0609 2 18V6ZM6 4C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H6ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9ZM7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12ZM17.5 8C17.8978 8 18.2794 7.84196 18.5607 7.56066C18.842 7.27936 19 6.89782 19 6.5C19 6.10218 18.842 5.72064 18.5607 5.43934C18.2794 5.15804 17.8978 5 17.5 5C17.1022 5 16.7206 5.15804 16.4393 5.43934C16.158 5.72064 16 6.10218 16 6.5C16 6.89782 16.158 7.27936 16.4393 7.56066C16.7206 7.84196 17.1022 8 17.5 8Z" fill="#1D4B54"/>
                </svg>
              </a>
            </div>
            <div className="flex flex-row md:flex-wrap align-middle  my-4 underline justify-center text-center ">
              <Link className="uppercase mx-1 mb-2 w-full w-24" to="/">Home</Link>
              <Link className="uppercase mx-1 mb-2 w-full w-24" to="/articles/page">Blog</Link>
              {/*<Link className="uppercase mx-1 mb-2 w-full w-24" to="/page">Pages</Link>*/}
              {/*<Link className="uppercase mx-1 mb-2 w-full w-24" to="/">Resources</Link>*/}
              <Link className="uppercase mx-1 mb-2 w-full w-24" to="/page/contact-us" >Contact us</Link>
            </div>
            <div className={'flex flex-col items-center md:items-start justify-center'}>
              <div>Address:  80 Celebration Drive</div>
              <div>Suwanee, GA, 30024</div>
              <div><a href="tel:678-800-1199">Phone: (678) 800-1199</a></div>
              <a href={'mailto:info@suwaneemasjid.org'}>Email:  info@suwaneemasjid.org</a>
            </div>
          </div>
          <p className={'w-full text-center'}>Copyright © {new Date().getUTCFullYear()} by <a className={'font-bold text-lg'} href="https://suwaneemasjid.org">www.suwaneemasjid.org</a> All Rights Reserved.</p>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
